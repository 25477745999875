var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"reports-cards"},[(
        _vm.checkPrivilege(
          _vm.usersPrivilegeData.usersPrivilegeEstablishmentRoles.viewData
        )
      )?_c('div',{staticClass:"reports-card"},[_c('router-link',{attrs:{"to":{ name: 'EstablishmentRoles' }}},[_c('img',{attrs:{"src":require("@/assets/images/establishment-role.svg")}}),_vm._v(_vm._s(_vm.$t("EstablishmentRoles.modelName"))+" ")])],1):_vm._e(),(
        _vm.checkPrivilege(
          _vm.usersPrivilegeData.usersPrivilegeUserValidSetting.viewData
        )
      )?_c('div',{staticClass:"reports-card"},[_c('router-link',{attrs:{"to":{ name: 'UserValidSettings' }}},[_c('img',{attrs:{"src":require("@/assets/images/UserValidSettings.svg")}}),_vm._v(_vm._s(_vm.$t("UserValidSettings.modelName"))+" ")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }